// import HeroBanner from "../../Asset/images/home-rice.jpg"
import React from "react";
import Slider from "react-slick";
import "./heroSlider.scss";
import banner1 from "../../Asset/images/banner1.png";
import banner2 from "../../Asset/images/banner2.png";
import banner3 from "../../Asset/images/banner3.png";
import { Link } from "react-router-dom";


export default function SimpleSlider() {
  var settings = {
    dots: true,
    speed: 2500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    infinite: true,
    autoplaySpeed: 2500,
  };
  return (
    <div id="HeroSlider" className="HeroSlider">
      <Slider {...settings}>
        <div>
          <div className="Heroslide">
            <img src={banner1} alt="" />
            <div className="Heroslide__content">
              <h2>
                Natureza em cada <span>sabor</span>
              </h2>
              <Link to="/contact">
                <button>Contactar</button>
              </Link>
            </div>
          </div>
        </div>
        <div>
          <div className="Heroslide">
            <img src={banner2} alt="" />
            <div className="Heroslide__content">
              <p>SABOROSOS, NUTRITIVOS E DELICIOSOS</p>
              <h2>
                Produtos de <span>excelência</span>
              </h2>
              <Link to="/contact">
                <button>Contactar</button>
              </Link>
            </div>
          </div>
        </div>
        <div>
          <div className="Heroslide">
            <img src={banner3} alt="" />
            <div className="Heroslide__content">
              <h2>
                Os melhores produtos da <span>nossa terra</span>
              </h2>
              <Link to="/contact">
                <button>Contactar</button>
              </Link>
            </div>
          </div>
        </div>
      </Slider>
    </div>
  );
}
