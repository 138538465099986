import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, Outlet, RouterProvider } from 'react-router-dom';
import Policy from './Components/Policy/Policy';
import Header from './Components/Header/Header';
import Footer from './Components/Footer/Footer';
import ContactUs from './Components/ContantUs/ContactUs';
import FruitsSlider from './Components/FruitsSlider/FruitsSlider';
import Mapsection from './Components/MapSection/Mapsection';

const router = createBrowserRouter([
  {
    element: <><Header /><Outlet /><Footer /></>,
    children: [
      { path: "/", element: <App /> },
      {
        path: "/policy",
        element: <Policy />,
      },
      // {
      //   path: "/contact",
      //   element: <ContactUs />,
      // },
      // {
      //   path: "/products",
      //   element: <FruitsSlider />,
      // },
      // {
      //   path: "/production",
      //   element: <Mapsection />,
      // },
      // {
      //   path: "/products",
      //   element: <FruitsSlider />,
      // },
    ]
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
