import React from "react";
import Grid from '@mui/material/Grid';
import "./policy.scss";

function Policy() {
  return (
    <>
      <section className="policy-section" id="policy-section">
        <div className="policy" >
          <div className="container">
            <Grid container >
              <Grid item xs={12}>
                <div className="policy_titleBlock">
                  <h2>Política de Qualidade e Segurança Alimentar</h2>
                  <p>A Hortoriba – Frutas e Legumes do Ribatejo, Lda considera essencial e prioritário a segurança e qualidade dos produtos que
                    comercializa, com vista ao reconhecimento e valorização dos produtos, de forma a satisfazer os requisitos dos clientes, superando as suas expetativas. Nesse sentido, a gerência assume o compromisso de cumprir e fazer cumprir de forma consciente, comunicar  interna e externamente a Política da Qualidade e Segurança Alimentar que se baseia nos seguintes princípios: </p>
                </div>
                <div className="policy_Content">
                  <div className="policy_Content_block">
                    <span className="subtitle">Segurança Alimentar</span>
                    <p>Promover uma cultura de segurança alimentar de forma a comercializar alimentos seguros,
                      com a comunicação, formação, sensibilização e envolvimento de todos os colaboradores, assim
                      como a disponibilização de todos os recursos necessários.</p>
                    <p>Assegurar a identificação de perigos relacionados com a Segurança dos produtos, estabelecendo medidas de controlo eficazes de forma a controlar os mesmos, aumentando a competitividade e produtividade.
                    </p>
                    <p>Desenvolver processos e metodologias com o objetivo de promover a melhoria contínua, através do envolvimento de todas as partes interessadas da organização.
                    </p>
                    <p>Garantir a conformidade dos requisitos legais e normativos.
                    </p>
                  </div>
                  <div className="policy_Content_block">
                    <span className="subtitle">Competitividade</span>
                    <p>Acompanhar os produtores de forma a monitorizar a qualidade das culturas, valorizar e desenvolver estratégias competitivas.
                    </p>
                    <p> Investir a nível dos recursos humanos, tecnologia, infraestruturas e equipamentos que otimizem a capacidade de produção.
                    </p>
                    <p>Estabelecer uma estreita cooperação com os fornecedores de forma a garantir as boas práticas agrícolas de acordo com as normas GLOBALG.A.P.</p>

                  </div>
                  <div className="policy_Content_block">
                    <span className="subtitle">Sustentabilidade</span>
                    <p>Comunicar de forma interativa com os nossos parceiros de negócio, no sentido da melhoria da nossa eficiência e sustentabilidade.</p>
                    <p>Garantir métodos que visem a redução, quer no desperdício alimentar quer nos resíduos, assegurando um destino final adequado para os resíduos gerados.</p>

                  </div>
                  <div className="policy_Content_block">
                    <span className="subtitle">Ética</span>

                    <p>A empresa assume-se pela postura socialmente justa e responsável com todos os seus parceiros de negócio e colaboradores.
                    </p>
                  </div>
                  <div className="policy_Content_block">
                    <span className="subtitle">Inovação</span>

                    <p>Desenvolver estratégias inovadoras para fazer face às exigências do mercado, aumentar a competitividade e promover a melhoria contínua.
                    </p>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
        <div className="container">
          {/* <div className="date">
            <p>Edição n.º 02</p>
            <p>17/07/2024 </p>
          </div> */}
        </div>
      </section>

    </>
  );
}

export default Policy;
