import React from "react";
import Fruit from "../../Asset/images/fruit.png"
import "./ourspecialties.scss";
import melon1 from "../../Asset/images/melon1.png"
import melon2 from "../../Asset/images/meloa2.png"
import melon3 from "../../Asset/images/melon3.png"
import melon4 from "../../Asset/images/melon4.png"


function Ourspecialties() {
  return (
    <>
      <div id="Ourspecialties" className="Ourspecialties">
        <div className="container">
          <div className="text-center">
            {/* <div className="section-label">Produção</div> */}
            <h3 className="section-title"> Trabalhamos com os <span> melhores produtos</span></h3>
            <p>Cuidadosamente colhidos e selecionados para levar até si o que melhor a natureza tem para nos dar. </p>
          </div>
          <div className="fruitsBox">
            <div className="fruitsBox__inner">
                <div className="productBox">
                    <img src={melon4} alt="melon4" />
                </div>
                <p>Abóbora</p>
            </div>
            <div className="fruitsBox__inner">
                <div className="productBox">
                    <img src={melon3} alt="melon3" />
                </div>
                <p>Melão</p>
            </div>
            <div className="fruitsBox__inner">
                <div className="productBox">
                    <img src={melon1} alt="melon1" />
                </div>
                <p>Melancia</p>
            </div>
            <div className="fruitsBox__inner">
                <div className="productBox">
                    <img src={melon2} alt="melon2" />
                </div>
                <p>Meloa</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Ourspecialties;
