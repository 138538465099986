import React from 'react'
import Target from "../../Asset/images/target.png"
import Star from "../../Asset/images/star.png"
import Bulb from "../../Asset/images/lighting-bulb.png"
import "./ourmission.scss";

function OurMission() {
    return (
        <>
            <div id='OurMission' className='OurMission'>
                <div className='OurMission__inner' style={{ backgroundColor: ' #eac000' }}>
                    <div className='iconBox'>
                        <img src={Target} alt="" />
                    </div>
                    <h4 className='title'>Missão</h4>
                    <p>Comercializar produtos hortofrutícolas de excelência e qualidade que superem as expectativas dos nossos clientes. </p>
                </div>
                <div className='OurMission__inner' style={{ backgroundColor: '#deaa00' }}>
                    <div className='iconBox'>
                        <img src={Bulb} alt="" />
                    </div>
                    <h4 className='title'>Visão</h4>
                    <p>Sermos uma referência na comercialização de frutos e hortícolas, de elevado valor percebido pelos nossos clientes, associados e colaboradores.</p>
                </div>
                <div className='OurMission__inner' style={{ backgroundColor: '#e0a00e' }}>
                    <div className='iconBox'>
                        <img src={Star} alt="" />
                    </div>
                    <h4 className='title'>Valores</h4>
                    <p>Sustentabilidade; Ética Competitividade; Inovação</p>
                </div>
            </div>
        </>
    )
}

export default OurMission;