import React, { useEffect, useState } from "react";
import Brand from "../../Asset/images/Brand.png";
import MenuIcon from "../../Asset/images/menu.png";
import "./header.scss";
import { Link } from "react-router-dom";

const Header = () => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    scrollToTop()
  }, []);

  const scrollToTop = () => {
    setOpen(false)
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const handleOnClickMenu = () => {
    setOpen((prev) => !prev);
  };

  const scrollToSection = (sectionId) => {
    setOpen(false)
    try {
      const element = document.getElementById(sectionId)
      let offset = 120
      if (sectionId === 'scroll-to-up') {
        offset = 100
      }
      if (element) {
        // Adjust as needed
        const elementTop = element.getBoundingClientRect().top + window.scrollY
        const scrollToPosition = elementTop - offset
        window.scrollTo({
          top: scrollToPosition,
          behavior: 'smooth',
        })
        return false
      }
    } catch (err) {
      console.error('Error scrolling to section:', err)
    }
  }
  return (
    <div className="header">
      <div className="container">
        <nav className="navbar">
          <Link to="/" className="themeBrand">
            <img src={Brand} alt="" />
          </Link>

          <button className="MenuBtn" onClick={handleOnClickMenu}>
            <img src={MenuIcon} alt="" />
          </button>
          <div className={open ? "menuList open" : "menuList"}>
            {/* <Link to="/" className="menuList__item">Empresa</Link> */}
            <Link
              to="/"
              className="menuList__item"
              onClick={scrollToTop}
            >
              Empresa
            </Link>
            <Link onClick={() => scrollToSection('production')} className="menuList__item">Produção</Link>
            <Link onClick={() => scrollToSection('products')} className="menuList__item">Produtos</Link>
            <Link onClick={() => scrollToSection('contact')} className="menuList__item">Contactos </Link>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Header;
