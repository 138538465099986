import React from "react";
import upArrow from "../../Asset/images/upArrow.png";
import "./footer.scss";
import Grid from '@mui/material/Grid';
import Brand from "../../Asset/images/Brand.png";

import InstagramIcon from '@mui/icons-material/Instagram';
import location from "../../Asset/images/location-white.png"
import iconemailc from "../../Asset/images/icon_email.png"
import phone from "../../Asset/images/icon_phone_green.svg"
import facebook from "../../Asset/images/icon_facebook.png"
import { Link } from "react-router-dom";

function Footer() {

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <>
      <footer className="footer">
        <div className="footer__top">
          <div className="container">
            <Grid container spacing={{ sm: 4, xs: 2.5 }}>
              <Grid item xs={12} sm={6} md={3}>
                <div className="footer_logo">
                  <img src={Brand} alt="" />
                  <p>A Hortoriba dedica-se diariamente à produção e comercialização de fruta e produtos hortícolas de excelência.</p>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <div className="footer-contact">
                  <h3 className="footer_linksTitle">Contactos</h3>
                  <div className="details_card">
                    <div className="icons">
                      <img src={location} alt="location" />
                    </div>
                    <div className="containt">
                      <p><a href="https://maps.app.goo.gl/NX1oazSJkeCgV7FU9" target="_blank">Rua da Liberdade, 117
						  </a><br />
                        <a href="https://maps.app.goo.gl/NX1oazSJkeCgV7FU9" target="_blank">Verdelho 2000-345 Santarém</a> </p>
                    </div>
                  </div>
                  <div className="details_card">
                    <div className="icons">
                      <img src={phone} alt="phone" />
                    </div>
                    <div className="containt">
						<p><a href="tel:+351913590040">+351 913 590 040</a></p>
                    </div>
                  </div>
					<div className="details_card" style={{ marginTop: '0px' }}>
                    <div className="icons">
                      <img src={phone} alt="phone" />
                    </div>
                    <div className="containt">
						<p><a href="tel:+351243429681">+351 243 429 681</a></p>
                    </div>
                  </div>
                  <div className="details_card">
                    <div className="icons">
                      <img src={iconemailc} alt="icon_email" />
                    </div>
                    <div className="containt">
						<p><a href="mailto:hortoriba@hortoriba.pt">hortoriba@hortoriba.pt</a></p>
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <div className="footer_unitLinks">
                  <h3 className="footer_linksTitle">Links Uteis</h3>

                  <ul className="footer-menu">
                    <li><Link to="/" >Empresa</Link></li>
                    <li><Link to="/">Produção</Link></li>
                    <li><Link to="/">Produtos</Link></li>
                    <li><Link to="/">Contactos </Link></li>
                    <li><Link to="/policy">Política de Qualidade e <br /> Segurança Alimentar</Link></li>
                    <li><Link to="/policy">Código de Conduta</Link></li>
                  </ul>

                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <div className="footer-socailLinks">
                  <h3 className="footer_linksTitle">Siga-nos nas Redes Sociais</h3>
                  <a href="#" className="details_card">
                    <div className="icons">
                      <img src={facebook} alt="facebook" />
                    </div>
                    <div className="containt">
                      <p>Facebook </p>
                    </div>
                  </a>
                  <a href="#" className="details_card">
                    <div className="icons">
                      <InstagramIcon />
                    </div>
                    <div className="containt">
                      <p>Instagram</p>
                    </div>
                  </a>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
        <div className="footer__bottom">
          <p>
            Copyright 2024 - Desenvolvido por Devtech.04 - All rights reserved.
          </p>
        </div>
      </footer>
      <button className="ScrollBtn" onClick={scrollToTop}>
        <img src={upArrow} alt="Scroll to top" />
      </button>
    </>
  );
}

export default Footer;
