import React from 'react'
import MapImg from "../../Asset/images/Production_Location.png"
import "./mapsection.scss";

function Mapsection() {
    return (
        <>
            <div className="mapsection" id={'production'}>
                <img src={MapImg} alt="" className='mapsection__img' />
                <div className="mapsection__content">
                    <p>Produção</p>
                    <h2>De onde vêm os nossos <span>produtos</span></h2>
                </div>
            </div>
        </>
    )
}

export default Mapsection;