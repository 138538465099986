/* eslint-disable no-dupe-keys */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useState } from "react";
import Slider from "react-slick";
import { Modal, Box } from "@mui/material";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import Icon1 from "../../Asset/images/icon_calibre.png";
import Icon2 from "../../Asset/images/icon_forma.png";
import Icon3 from "../../Asset/images/icon_textura.png";
import Icon4 from "../../Asset/images/icon_color.png";

import product1 from "../../Asset/images/product1.png";
import product2 from "../../Asset/images/product2.png";
import product3 from "../../Asset/images/product3.png";
import product4 from "../../Asset/images/product4.png";
import product5 from "../../Asset/images/product5.png";
import product6 from "../../Asset/images/product6.png";
import product7 from "../../Asset/images/product7.png";
import product8 from "../../Asset/images/product8.png";
import product9 from "../../Asset/images/product9.png";
import product10 from "../../Asset/images/product10.png";
import product11 from "../../Asset/images/product11.png";
import dayjs from "dayjs";

import melon3 from "../../Asset/images/melon3.png";
import melon4 from "../../Asset/images/melon4.png";
import melon1 from "../../Asset/images/melon1.png";
import plant from "../../Asset/svg/icone_handholdingplant.svg";
import carrot from "../../Asset/images/icone_handholdingcarrot.png";
import icone_fruit from "../../Asset/images/icone_fruitbasket.png";

import "./fruitsslider.scss";

// Dados Estáticos Atualizados
const fruitsData = [
  {
    title: "Melão Branco",
    // description: "Some description",
    categories: [
      { color: "#E0A00E", imageUrl: melon3, id: "category1" },
      { color: "#EDB405", imageUrl: plant, id: "category2" },
      { color: "#A7D379", imageUrl: carrot, id: "category3" },
      { color: "#593A18", imageUrl: icone_fruit, id: "category4" },
    ],
    url: product3,
  },
  {
    title: "Melão Verde",
    description: "",
    categories: [
      { color: "#E0A00E", imageUrl: melon3, id: "category1" },
      { color: "#EDB405", imageUrl: plant, id: "category2" },
      { color: "#A7D379", imageUrl: carrot, id: "category3" },
      { color: "#593A18", imageUrl: icone_fruit, id: "category4" },
    ],
    url: product2,
  },
  {
    title: "Melão Amarelo",
    // description: "Some description",
    categories: [
      { color: "#E0A00E", imageUrl: melon3, id: "category1" },
      { color: "#EDB405", imageUrl: plant, id: "category2" },
      { color: "#A7D379", imageUrl: carrot, id: "category3" },
      { color: "#593A18", imageUrl: icone_fruit, id: "category4" },
    ],
    url: product1,
  },
  {
    title: "Melancia Preta",
    // description: "c/sementes",
    categories: [
      { color: "#E0A00E", imageUrl: melon1, id: "category1" },
      { color: "#EDB405", imageUrl: plant, id: "category2" },
      { color: "#A7D379", imageUrl: carrot, id: "category3" },
      { color: "#593A18", imageUrl: icone_fruit, id: "category4" },
    ],
    url: product6,
  },
  {
    title: "Melancia",
    description: "s/sementes",
    categories: [
      { color: "#E0A00E", imageUrl: melon1, id: "category1" },
      { color: "#EDB405", imageUrl: plant, id: "category2" },
      { color: "#A7D379", imageUrl: carrot, id: "category3" },
      { color: "#593A18", imageUrl: icone_fruit, id: "category4" },
    ],
    url: product5,
  },
  {
    title: "Melancia riscada",
    // description: "s/sementes",
    categories: [
      { color: "#E0A00E", imageUrl: melon1, id: "category1" },
      { color: "#EDB405", imageUrl: plant, id: "category2" },
      { color: "#A7D379", imageUrl: carrot, id: "category3" },
      { color: "#593A18", imageUrl: icone_fruit, id: "category4" },
    ],
    url: product4,
  },
  {
    title: "Melancia Alongada",
    description: "c/sementes",
    categories: [
      { color: "#E0A00E", imageUrl: melon1, id: "category1" },
      { color: "#EDB405", imageUrl: plant, id: "category2" },
      { color: "#A7D379", imageUrl: carrot, id: "category3" },
      { color: "#593A18", imageUrl: icone_fruit, id: "category4" },
    ],
    url: product7,
  },
  {
    title: "Meloa Gália",
    // description: "Some description",
    categories: [
      { color: "#E0A00E", imageUrl: melon3, id: "category1" },
      { color: "#EDB405", imageUrl: plant, id: "category2" },
      { color: "#A7D379", imageUrl: carrot, id: "category3" },
      { color: "#593A18", imageUrl: icone_fruit, id: "category4" },
    ],
    url: product9,
  },
  {
    title: "Meloa Cantaloupe",
    // description: "Some description",
    categories: [
      { color: "#E0A00E", imageUrl: melon3, id: "category1" },
      { color: "#EDB405", imageUrl: plant, id: "category2" },
      { color: "#A7D379", imageUrl: carrot, id: "category3" },
      { color: "#593A18", imageUrl: icone_fruit, id: "category4" },
    ],
    url: product8,
  },
  {
    title: "Abóbora Butternut",
    // description: "Some description",
    categories: [
      { color: "#E0A00E", imageUrl: melon3, id: "category1" },
      { color: "#EDB405", imageUrl: plant, id: "category2" },
      { color: "#A7D379", imageUrl: carrot, id: "category3" },
      { color: "#593A18", imageUrl: icone_fruit, id: "category4" },
    ],
    url: product10,
  },
  {
    title: "Abóbora Musquee",
    description: "de provence",
    categories: [
      { color: "#E0A00E", imageUrl: melon4, id: "category1" },
      { color: "#EDB405", imageUrl: plant, id: "category2" },
      { color: "#A7D379", imageUrl: carrot, id: "category3" },
      { color: "#593A18", imageUrl: icone_fruit, id: "category4" },
    ],
    url: product11,
  },
];

// Remover a duplicação e usar apenas os dados originais
const extendedFruitsData = [...fruitsData];

// Estilos para o Modal
const modalStyles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "12px",
};

// Função para renderizar o conteúdo dos modais com base no título
const renderContent = (title) => {
  switch (title) {
    case "Melão Amarelo":
      return {
        calibre: (
          <>
            <p>4/5 (3,3 kg - 5,0kg);</p>
            <p>6/8 (1,5kg - 3,3kg)</p>
          </>
        ),
        forma: "Redonda",
        textura: "Firme",
        cor: (
          <>
            <p>Amarelo</p>
            <p>Polpa branco-esverdeada</p>
          </>
        ),
      };
    case "Melão Branco":
      return {
        calibre: (
          <>
            <p>4/5 (3,3 kg - 5,0kg);</p>
            <p>6/8 (1,5kg - 3,3kg)</p>
          </>
        ),
        forma: "Oval/Redonda",
        textura: "Firme",
        cor: (
          <>
            <p>Branca</p>
            <p>Polpa Branca</p>
          </>
        ),
      };
    case "Melão Verde":
      return {
        calibre: (
          <>
            <p>4/5 (3,3 kg - 5,0kg);</p>
            <p>6/8 (1,5kg - 3,3kg)</p>
          </>
        ),
        forma: "Oval",
        textura: "Firme",
        cor: (
          <>
            <p>Verde</p>
            <p>Polpa Creme</p>
          </>
        ),
      };
    case "Meloa Cantaloupe":
      return {
        calibre: (
          <>
            <p>4/8 (1,1kg - 2kg); 5/10 (0,9kg - 1,1kg);</p>
            <p>6/12 (0,8kg - 0,9kg); 7/14 (0,7kg - 0,8kg)</p>
            <p>8/16 (0,5kg - 0,7kg)</p>
          </>
        ),
        forma: "Redonda",
        textura: "Firme",
        cor: (
          <>
            <p>Verde/ Amarela</p>
            <p>Polpa Laranja/Salmão</p>
          </>
        ),
      };
    case "Meloa Gália":
      return {
        calibre: (
          <>
            <p>4/8 (1,1kg - 2,0kg); 5/10 (0,9kg - 1,1kg);</p>
            <p>6/12 (0,8kg - 0,9kg); 7/14 (0,7kg - 0,8kg)</p>
            <p>8/16 (0,5kg - 0,7kg)</p>
          </>
        ),
        forma: "Redonda",
        textura: "Firme",
        cor: (
          <>
            <p>Verde/ Amarela</p>
            <p>Polpa branco-esverdeada</p>
          </>
        ),
      };
    case "Melancia Preta":
      return {
        calibre: (
          <>
            <p>2/3 (5,7kg - 10,0kg); 4/5 (3,7kg - 5,7kg);</p>
            <p>6 (2,0kg - 3,7kg)</p>
          </>
        ),
        forma: "Redonda",
        textura: "Firme",
        cor: (
          <>
            <p>Verde-escuro</p>
            <p>Polpa vermelha intensa</p>
            <p>(Pode conter algumas sementes, mas pouco desenvolvidas, com embriões brancos e macios, totalmente comestíveis)</p>
          </>
        ),
      };
    case "Melancia riscada":
      return {
        calibre: (
          <>
            <p>2/3 (5,7kg - 10,0kg); 4/5 (3,7kg - 5,7kg);</p>
            <p>6/8 (2,0kg - 3,7kg)</p>
          </>
        ),
        forma: "Redonda",
        textura: "Firme",
        cor: (
          <>
            <p>Riscada Verde Claro-Verde Escuro</p>
            <p>Polpa Vermelha</p>
            <p>(As suas sementes são desenvolvidas, mas de tamanho reduzido)</p>
          </>
        ),
      };
    case "Melancia Alongada":
      return {
        calibre: (
          <>
            <p>2/3 (6,0kg - 12,0kg);</p>
            <p>4/5 (3,5kg - 6,0kg);</p>
          </>
        ),
        forma: (
          <>
            <p>Oval</p>
            <p>(Frutos geralmente de grandes dimensões)</p>
          </>
        ),
        textura: "Firme",
        cor: (
          <>
            <p>Riscada Verde Claro-Verde Escuro</p>
            <p>Polpa Vermelha</p>
          </>
        ),
      };
    case "Abóbora Butternut":
      return {
        calibre: (
          <>
            <p>6/7 (2,00kg - 1,40kg); 8/9 (1,40kg - 1,10kg);</p>
            <p>10/11 (0,90kg - 1,10kg);</p>
            <p>12/13 (0,80kg - 0,90kg)</p>
          </>
        ),
        forma: (
          <>
            <p>Cabaça</p>
            <p>(típica da variedade)</p>
          </>
        ),
        textura: "Firme",
        cor: (
          <>
            <p>Bege</p>
            <p>Polpa Laranja</p>
          </>
        ),
      };
    case "Abóbora Musquee":
      return {
        calibre: (
          <>
            <p>N/A</p>
          </>
        ),
        forma: (
          <>
            <p>Redonda e com formação de</p>
            <p>grandes gomos</p>
            <p>(típica da variedade)</p>
          </>
        ),
        textura: "Firme",
        cor: (
          <>
            <p>Verde/ Amarela</p>
            <p>Polpa Laranja</p>
          </>
        ),
      };
    default:
      return {
        calibre: (
          <>
            <p>4/8 (1,1kg - 2kg); 5/10 (0,9kg - 1,1kg);</p>
            <p>6/12 (0,8kg - 0,9kg); 7/14 (0,7kg - 0,8kg)</p>
          </>
        ),
        forma: "Oval",
        textura: "Firme",
        cor: (
          <>
            <p>Verde/ Amarela</p>
            <p>Polpa branco-esverdeada</p>
          </>
        ),
      };
  }
};

function FruitsSlider() {
  const [openModal, setOpenModal] = useState(null);
  const [currentDate, setCurrentDate] = useState(dayjs());
  const [title, setTitle] = useState("");

  const months = [
    "Jan.",
    "Fev.",
    "Mar.",
    "Abr.",
    "Mai.",
    "Jun.",
    "Jul.",
    "Ago.",
    "Set.",
    "Out.",
    "Nov.",
    "Dez.",
  ];

  const highlightMonths = ["Abr.", "Mai.", "Jun.", "Jul."];
  const highlightMonthsHarvest = ["Jun.", "Jul.", "Ago.", "Set."];
  const highlightMonthsDisponível = ["Jun.", "Jul.", "Ago.", "Set."];
  const NewhighlightMonthsDisponível = [
    "Jan.",
    "Fev.",
    "Mar.",
    "Jun.",
    "Jul.",
    "Ago.",
    "Set.",
    "Out.",
    "Nov.",
    "Dez.",
  ];
  const NewhighlightMonthsHarvest = ["Jun.", "Jul.", "Ago.", "Set.", "Out."];

  const handleMonthClick = (monthIndex) => {
    const newDate = currentDate.month(monthIndex);
    setCurrentDate(newDate);
  };

  const handleOpenModal = (categoryId, fruitTitle) => {
    setOpenModal(categoryId);
    setTitle(fruitTitle);
  };

  const handleCloseModal = () => {
    setOpenModal(null);
    setTitle("");
  };

  const settings = {
    dots: true,
    speed: 500,
    slidesToShow: 3, // 3 slides visíveis
    slidesToScroll: 1, // Avança 1 slide por vez
    autoplay: false,
    infinite: false, // Desativa o loop infinito
    autoplaySpeed: 500,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1060,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const { calibre, forma, textura, cor } = renderContent(title);

  return (
    <div className="FruitsSlider" id="products">
      <div className="container">
        <div className="text-center">
          <p>Produtos</p>
          <h3 className="section-title">
            Produtos e <span>especialidades</span>
          </h3>
        </div>
        <div className="FruitsSliderBox">
          <Slider {...settings}>
            {extendedFruitsData.map((fruit, index) => (
              <div key={index}>
                <div className="fruitsSlide">
                  <div className="fruitsSlide__img">
                    <img src={fruit.url} alt={fruit.title} />
                  </div>
                  <div className="fruitsSlide__content">
                    <h4 className="fruitsSlide__title">
                      {fruit.title}
                      <span>{fruit.description}</span>
                    </h4>
                    <div className="fruitscategory">
                      {fruit.categories.map((category) => (
                        <button
                          key={category.id}
                          className="fruitscategory__inner"
                          style={{ backgroundColor: category.color, border: 'none' }} // Adicionado border: none
                          onClick={() => handleOpenModal(category.id, fruit.title)}
                          aria-label={`Abrir detalhes da categoria ${category.id} para ${fruit.title}`}
                        >
                          <img
                            src={category.imageUrl}
                            alt={`${fruit.title} categoria ${category.id}`}
                          />
                        </button>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>

      {/* Modais */}

      {/* Modal para Categoria 1 - Detalhes do Fruto */}
      <Modal
        open={openModal === "category1"}
        onClose={handleCloseModal}
        className="custom_modal"
      >
        <Box sx={modalStyles}>
          <div className="FruitsBoxModel">
            <div className="FruitsBox">
              <div className="FruitsBox__icon">
                <img src={Icon1} alt="Ícone Calibre" />
              </div>
              <div className="FruitsBox__content">
                <h2>Calibre</h2>
                {calibre}
              </div>
            </div>
            <div className="FruitsBox">
              <div className="FruitsBox__icon">
                <img src={Icon2} alt="Ícone Forma" />
              </div>
              <div className="FruitsBox__content">
                <h2>Forma</h2>
                <p>{forma}</p>
              </div>
            </div>
            <div className="FruitsBox">
              <div className="FruitsBox__icon">
                <img src={Icon3} alt="Ícone Textura" />
              </div>
              <div className="FruitsBox__content">
                <h2>Textura</h2>
                <p>{textura}</p>
              </div>
            </div>
            <div className="FruitsBox">
              <div className="FruitsBox__icon">
                <img src={Icon4} alt="Ícone Cor" />
              </div>
              <div className="FruitsBox__content">
                <h2>Cor</h2>
                {cor}
              </div>
            </div>
          </div>
        </Box>
      </Modal>

      {/* Modal para Categoria 2 - Plantação */}
      <Modal
        open={openModal === "category2"}
        onClose={handleCloseModal}
        className="custom_modal CateoryCalendar CateoryFirst"
      >
        <Box sx={modalStyles}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["DateCalendar", "DateCalendar", "DateCalendar"]}>
              <h2 className="title">Plantação</h2>
              <div className="monthsContainer" style={styles.monthsContainer}>
                {months.map((month, index) => {
                  const isHighlighted = highlightMonths.includes(month);
                  return (
                    <div
                      key={month}
                      style={{
                        ...styles.month,
                        color: isHighlighted ? "#fff" : "#555",
                        backgroundColor: isHighlighted ? "#EDB405" : "transparent",
                        fontWeight: isHighlighted ? "bold" : "normal",
                      }}
                      onClick={() => handleMonthClick(index)}
                    >
                      {month}
                    </div>
                  );
                })}
              </div>
            </DemoContainer>
          </LocalizationProvider>
        </Box>
      </Modal>

      {/* Modal para Categoria 3 - Colheita */}
      <Modal
        open={openModal === "category3"}
        onClose={handleCloseModal}
        className="custom_modal CateoryCalendar CateorySecond"
      >
        <Box sx={modalStyles}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["DateCalendar", "DateCalendar", "DateCalendar"]}>
              <h2 className="title">Colheita</h2>
              <div style={styles.monthsContainer}>
                {months.map((month, index) => {
                  const isHighlighted =
                    title === "Abóbora Butternut"
                      ? NewhighlightMonthsHarvest.includes(month)
                      : highlightMonthsHarvest.includes(month);
                  return (
                    <div
                      key={month}
                      style={{
                        ...styles.month,
                        color: isHighlighted ? "#fff" : "#555",
                        backgroundColor: isHighlighted ? "#A7D379" : "transparent",
                        fontWeight: isHighlighted ? "bold" : "normal",
                      }}
                      onClick={() => handleMonthClick(index)}
                    >
                      {month}
                    </div>
                  );
                })}
              </div>
            </DemoContainer>
          </LocalizationProvider>
        </Box>
      </Modal>

      {/* Modal para Categoria 4 - Disponível */}
      <Modal
        open={openModal === "category4"}
        onClose={handleCloseModal}
        className="custom_modal CateoryCalendar CateoryThird"
      >
        <Box sx={modalStyles}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["DateCalendar", "DateCalendar", "DateCalendar"]}>
              <h2 className="title">Disponível</h2>
              <div style={styles.monthsContainer}>
                {months.map((month, index) => {
                  const isHighlighted =
                    title === "Abóbora Butternut"
                      ? NewhighlightMonthsDisponível.includes(month)
                      : highlightMonthsDisponível.includes(month);
                  return (
                    <div
                      key={month}
                      style={{
                        ...styles.month,
                        color: isHighlighted ? "#fff" : "#555",
                        backgroundColor: isHighlighted ? "#593A18" : "transparent",
                        fontWeight: isHighlighted ? "bold" : "normal",
                      }}
                      onClick={() => handleMonthClick(index)}
                    >
                      {month}
                    </div>
                  );
                })}
              </div>
            </DemoContainer>
          </LocalizationProvider>
        </Box>
      </Modal>
    </div>
  );
}

const styles = {
  container: {
    textAlign: "center",
    fontFamily: "Arial, sans-serif",
  },
  title: {
    color: "#F5A623", // Cor amarela
    fontSize: "36px",
    fontWeight: "bold",
  },
  currentMonth: {
    margin: "20px 0",
    fontSize: "18px",
    color: "#333",
  },
  monthsContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    gap: "10px",
  },
  month: {
    margin: "10px",
    padding: "10px",
    cursor: "pointer",
    fontSize: "16px",
    width: "70px",
    height: "70px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "18px",
  },
};

export default FruitsSlider;
