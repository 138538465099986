import React from "react";
import Hortoriba from "../../Asset/images/hortoriba.png";
import "./whoweare.scss";

function WhoWeAre() {
  return (
    <>
      <div id="whoweare" className="whoweare">
        <div className="container">
          <div className="contentwrapper">
            <div className="contentwrapper__image">
              <img src={Hortoriba} alt="" />
            </div>
            <div className="contentwrapper__Content">
              <div className="section-label">Quem Somos</div>
              <h3 className="section-title">
                O que precisa saber sobre a <span> Hortoriba</span>
              </h3>
              <p>
                Recentes no mercado, mas com muitos anos de experiência.
              </p>
              <p> A Hortoriba dedica-se diariamente à produção e comercialização de fruta e produtos hortícolas de excelência.</p>
              <p>Estabelecemos padrões rigorosos de qualidade em todas as nossas etapas de produção, porque
                acreditamos que a qualidade dos nossos produtos é a chave para a satisfação do cliente.</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default WhoWeAre;
