import HeroSlider from "./Components/HeroSlider/HeroSlider";
import WhoWeAre from "./Components/WhoWeAre/WhoWeAre";
import OurMission from "./Components/OurMission/OurMission";
import Ourspecialties from "./Components/Ourspecialties/Ourspecialties";
import FruitsSlider from "./Components/FruitsSlider/FruitsSlider";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./App.scss";
import ContactUs from "./Components/ContantUs/ContactUs";
import Mapsection from "./Components/MapSection/Mapsection";

function App() {
  return (
    <div className="App">
      <HeroSlider />
      <WhoWeAre />
      <OurMission />
      <Mapsection />
      <Ourspecialties />
      <FruitsSlider />
      <ContactUs />
    </div>
  );
}

export default App;
